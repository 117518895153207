<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
        </v-col>
      </v-row>
    </v-card>
    <v-data-table
      class="custom-table ma-5 mt-16"
      :headers="headers"
      :items="clients"
      :server-items-length="clients.count"
      :row-per-page="limit"
      :footer-props="{
        itemsPerPageText: '',
        itemsPerPageOptions: [],
      }"
      dense
      :hide-default-footer="clients.length < 5"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          color="primary darken-2"
          text
          x-small
          @click="$refs.manageClientDialogRef.open(item)"
        >
          {{ $t("label.edit") }}
        </v-btn>

        <v-btn
          color="error darken-2"
          text
          x-small
          @click="confirmDeleteClient(item)"
        >
          {{ $t("label.delete") }}
        </v-btn>
      </template>
    </v-data-table>

    <v-fab-transition>
      <v-btn
        fab
        fixed
        right
        bottom
        color="var(--v-primary-base)"
        class="add-user-btn"
        @click="$refs.manageClientDialogRef.open()"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <manage-clients-dialog
      ref="manageClientDialogRef"
      @refresh-controller="fetchClientsTable"
    ></manage-clients-dialog>

    <confirm-dialog ref="confirm"></confirm-dialog>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapActions, mapGetters } from "vuex";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import ManageClientsDialog from "@/components/dialogs/ManageClientsDialog.vue";

export default {
  components: { ConfirmDialog, ManageClientsDialog },
  name: "clients",
  async mounted() {
    this.fetchClientsTable();
  },
  data() {
    return {
      limit: 10,
      headers: [
        {
          text: i18n.t("label.id"),
          align: "start",
          value: "id",
        },
        { text: i18n.t("label.name"), value: "name" },
        { text: i18n.t("label.action"), value: "action" },
      ],
      options: {
        bgcolor: "error darken-3",
        color: "white",
        width: 400,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },
  methods: {
    ...mapActions({
      fetchClientsTable: "clients/fetchClientsTable",
      deleteClients: "clients/deleteClients",
    }),

    async confirmDeleteClient(item) {
      if (
        await this.$refs.confirm.open(
          `${i18n.t("controller.delete-controller")}`,
          `${i18n.t("controller.delete-message", {
            ip: item.ip_address,
          })}`,
          this.options
        )
      ) {
        this.deleteRecord(item.id);
      }
    },
    deleteRecord(id) {
      this.deleteClients(id)
        .then(() => {
          this.fetchClientsTable();
        })
        .catch((err) => console.log("Delete client error :", err));
    },
  },
  computed: {
    ...mapGetters({
      clients: "clients/getClientsTable",
    }),
  },
};
</script>

<style></style>
