<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-form lazy-validation ref="form" @submit.prevent="submit">
        <v-card-title>
          <span class="text-h5" v-if="input.id">{{
            `${$t("common.update")} ${$tc("controller.title", 1)}`
          }}</span>
          <span class="text-h5" v-else>{{
            `${$t("common.add")} ${$tc("controller.title", 1)}`
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="form">
              <v-col cols="12">
                <v-text-field
                  :label="`${$t('label.name')}*`"
                  class="my-0 py-0"
                  v-model="input.name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1" color="grey darken-1" text @click="close">
            {{ $t("common.close") }}
          </v-btn>
          <v-btn class="mb-1" color="primary darken-1" type="submit">
            {{ $t("common.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      input: {},
    };
  },
  methods: {
    ...mapActions({
      addClients: "clients/addClients",
      updateClients: "clients/updateClients",
    }),
    submit() {
      if (this.$refs.form.validate()) {
        const params = {
          name: this.input.name
        };
        if (this.input.id) {
          // call update controller api
          this.updateClients({ params, id: this.input.id })
            .then(() => this.$emit("refresh-controller"))
            .catch((error) => console.log(error));
        } else {
          // call add user api
          console.log("params: ", params);
          this.addClients(params)
            .then(() => this.$emit("refresh-controller"))
            .catch((error) => console.log(error));
        }
        this.close();
      }
    },
    open(item) {
      this.input = { ...item };
      this.dialog = true;
    },
    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
    },
  },
};
</script>
